module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="container">\r\n        <div class="footer-nav">\r\n            <dl>\r\n                <dt><a href="productList.html">TBR</a></dt>\r\n                <dd><a href="productList.html">LONG HAUL</a></dd>\r\n                <dd><a href="productList.html">REGIONAL</a></dd>\r\n                <dd><a href="productList.html">MINING</a></dd>\r\n                <dd><a href="productList.html">URBAN</a></dd>\r\n                <dd><a href="productList.html">SNOW</a></dd>\r\n            </dl>\r\n            <dl>\r\n                <dt><a href="productList1.html">OTR</a></dt>\r\n                <dd><a href="productList.html">RDT</a></dd>\r\n                <dd><a href="productList.html">ADT, LOADER AND DOZER</a></dd>\r\n                <dd><a href="productList.html">GRADER</a></dd>\r\n                <dd><a href="productList.html">CRANE</a></dd>\r\n                <dd><a href="productList.html">UNDERGROUND</a></dd>\r\n            </dl>\r\n            <dl>\r\n                <dt><a href="productList2.html">RADIAL AGR</a></dt>\r\n            </dl>\r\n            <dl>\r\n                <dt><a href="contact.html">GET IN TOUCH</a></dt>\r\n                <dd class="email"><a href="mailto:info@utctire.com">E-mail:info@utctire.com</a></dd>\r\n                <dd><a href="javascript:;"><i class="iconfont icon-facebookfacebook11"></i></a></dd>\r\n            </dl>\r\n        </div>\r\n    </div>\r\n    <div class="footer-copy" style="background: url(' +
((__t = (require('./images/copy-bg.png'))) == null ? '' : __t) +
')no-repeat;background-size:cover">\r\n        <div class="container">\r\n            <div class="left">\r\n                © 2022 QINGDAO UNITOUR TIRE COMPANY LIMITED.ALL RIGHTS RESERVED.\r\n            </div>\r\n            <div class="right">\r\n                <a href="javascript:void(0);" data-open="map" class="site-map">Sitemap</a><span class="site-map">|</span>\r\n                <a href="https://leadto.com.cn/" target="_blank">Designed By LTD</a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}