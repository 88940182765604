import './index.scss'
// 点击打开手机导航栏
$('.menu').on('click', function () {
        $(".menu_shadow").addClass('active')
        $('.hamburger').addClass('active')
        $('.root-mask-bottom').fadeIn(200)
        $('.header-nav-mob').addClass('active')
        $(".yinying").show()
})
$('[data-open="cmenu"]').on('click', function () {
    $('.hamburger').removeClass('active')
    $('.root-mask-bottom').fadeOut(200)
    $('.header-nav-mob').removeClass('active')
    $(".yinying").hide()
    $('.header-nav-mob ul li .sub-nav').stop().slideUp(200)
    $('.header-nav-mob ul li .sub-nav-title').removeClass('active')
})
$(".yinying").click(function () {
    $('.hamburger').removeClass('active')
    $('.root-mask-bottom').fadeOut(200)
    $('.header-nav-mob').removeClass('active')
    $(".yinying").hide()
    $('.header-nav-mob ul li .sub-nav').stop().slideUp(200)
    $('.header-nav-mob ul li .sub-nav-title').removeClass('active')
})